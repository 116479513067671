<template>
  <v-layout>
    <v-container fluid class="pt-4 mb-12">
      <v-container fluid class="my-0 py-0">
        <v-form ref="form" v-model="valid1">
          <v-row>
            <v-col cols="6">
              <p class="display-1 text--primary">
                {{ formTitle }}
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="d-flex justify-end align-content-center align-center">
              <v-autocomplete
                v-if="prerecord"
                v-model="prerecordId"
                :items="preRecordsByCompany"
                :loading="loadingPreRecords"
                :no-data-text="$t('general.fields.noDataAvailable')"
                :rules="requireRules"
                class="required"
                item-color="primary"
                item-text="prerecords_name"
                item-value="id"
                small-chips
                persistent-hint
                small
                flat
                outlined
                dense
                color="primary"
                hide-details
                clearable
                @change="changePrerecordField($event)"
              >
                <template v-slot:label>
                  {{ $t("pre-records.fields.name") }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip small>
                    <span>{{ item.prerecords_name | truncate(50) }}</span>
                  </v-chip>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 300px">
                    <read-more
                      :classes="'mb-0'"
                      :font-size="14"
                      :max-chars="80"
                      :text="item.prerecords_name"
                    />
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="mx-2 d-flex justify-end" cols="2">
              <v-checkbox
                v-model="prerecord"
                :label="$t('menu.prerecords')"
                color="primary"
              />
            </v-col>
          </v-row>
          <div>
            <v-row>
              <v-col cols="12">
                <v-card outlined>
                  <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("records.titles.primary_location") }}</h3>
                  </v-app-bar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.storage_device_type_id"
                            :disabled="editedIndex && editedIndex > 0"
                            :items="storageDeviceTypesActiveByCompany"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            item-color="primary"
                            item-text="storage_type_name"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            @change="changeField($event, 'sdt')"
                        >
                          <template v-slot:label>
                            {{ $t("storage.fields.storage_type") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.storage_type_name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.storage_type_name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.process"
                            :disabled="
                              !recordSelected.storage_device_type_id ||
                              (editedIndex && editedIndex > 0)
                            "
                            :items="processByStage"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            item-color="primary"
                            item-text="proccess_name"
                            item-value="id"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            @change="changeField($event, 'process')"
                        >
                          <template v-slot:label>
                            {{ $t("records.fields.process") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.proccess_name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.proccess_name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="3">
                        <v-datetime-picker
                            v-model="computedRecordDate"
                            :label="$t('records.fields.date')"
                            :locale="$i18n.locale"
                            :required="true"
                            :show-date="true"
                            :show-time="true"
                            :text-field-props="textProps"
                            color="primary"
                            date-format="dd / MM / yyyy"
                            time-format="HH:mm"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card outlined>
                  <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("inventory.titles.locations") }}</h3>
                  </v-app-bar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.location_origin"
                            :clearable="true"
                            :disabled="!recordSelected.storage_device_type_id"
                            :items="origin.locations"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            item-text="name"
                            item-value="locationId"
                            return-object
                            item-color="primary"
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            :loading="loadings.origin.locations"
                            @click:clear="fetchLocations('', 'origin')"
                            @change="fetchStorages(null, recordSelected.location_origin, 'origin', 'sdi_origin')"
                            @update:search-input="fetchLocations($event, 'origin')"
                        >
                          <template v-slot:label>
                            {{ $t("records.fields.location_origin") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.sdi_origin"
                            :clearable="true"
                            :disabled="!recordSelected.location_origin"
                            :error="errorSDO"
                            :error-messages="errorSDO ? $t('general.rules.required') : ''"
                            :items="origin.storages"
                            :multiple="
                              recordSelected.process
                                ? recordSelected.process.processType === 'AGRICULTURAL' &&
                                  editedIndex === -1
                                : false
                            "
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            item-color="primary"
                            item-text="control_label"
                            item-value="storageDeviceId"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            @change="selectSDTisFarm()"
                            @input="clearSDO($event)"
                            @click:clear="fetchStorages('', recordSelected.location_origin, 'origin', 'sdi_origin')"
                            @update:search-input="fetchStorages($event, recordSelected.location_origin, 'origin', 'sdi_origin')"
                        >
                          <template v-slot:label>
                            {{ $t("records.fields.originReg") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template
                              v-slot:selection="{ item, index, attrs, select, selected }"
                          >
                            <v-chip v-if="index === 0" small>
                              <span>{{ item.control_label }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                            >(+{{ recordSelected.sdi_origin.length - 1 }}
                      {{ $t("general.titles.others") }})</span
                            >
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 360px">
                              <v-checkbox
                                  v-if="
                          recordSelected.process
                            ? recordSelected.process.processType ===
                                'AGRICULTURAL' && editedIndex === -1
                            : false
                        "
                                  :hide-details="true"
                                  :input-value="attrs.inputValue"
                                  class="pt-2 pb-2 mt-0"
                                  color="primary"
                              >
                                <template v-slot:label>
                                  <read-more
                                      :classes="'mb-0'"
                                      :font-size="14"
                                      :max-chars="80"
                                      :text="item.control_label"
                                  />
                                </template>
                              </v-checkbox>
                              <read-more
                                  v-else
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.control_label"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row
                        v-if="
                          recordSelected.process &&
                          recordSelected.process.processType !== 'AGRICULTURAL'
                        "
                    >
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.location_destination"
                            :clearable="true"
                            :disabled="!recordSelected.storage_device_type_id"
                            :items="destination.locations"
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            item-text="name"
                            item-color="primary"
                            item-value="locationId"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            :loading="loadings.origin.locaions"
                            @click:clear="fetchLocations('', 'destination')"
                            @change="fetchStorages(null, recordSelected.location_destination, 'destination', 'sdi_destination', true)"
                            @update:search-input="fetchLocations($event, 'destination')"
                        >
                          <template v-slot:label>
                            {{ $t("records.fields.location_destination") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip small>
                              <span>{{ item.name | truncate(50) }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 300px">
                              <read-more
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.name"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                            v-model="recordSelected.sdi_destination"
                            :clearable="true"
                            :disabled="!recordSelected.location_destination"
                            :error="errorSDD"
                            :error-messages="errorSDD ? $t('general.rules.required') : ''"
                            :items="destination.storages"
                            :multiple="
                              recordSelected.process
                                ? recordSelected.process.processType === 'AGRICULTURAL'
                                : false
                            "
                            :no-data-text="$t('general.fields.noDataAvailable')"
                            :rules="requireRules"
                            class="required"
                            item-color="primary"
                            item-text="control_label"
                            item-value="storageDeviceId"
                            return-object
                            small-chips
                            persistent-hint
                            small
                            flat
                            outlined
                            dense
                            color="primary"
                            clearable
                            @input="clearSDD($event)"
                            @click:clear="fetchStorages('', recordSelected.location_destination, 'destination', 'sdi_destination', true)"
                            @update:search-input="fetchStorages($event, recordSelected.location_destination, 'destination', 'sdi_destination', true)"
                        >
                          <template v-slot:label>
                            {{ $t("records.fields.destinationRed") }}
                            <strong class="red--text">*</strong>
                          </template>
                          <template
                              v-slot:selection="{ item, index, attrs, select, selected }"
                          >
                            <v-chip v-if="index === 0" small>
                              <span>{{ item.control_label }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption"
                            >(+{{ recordSelected.sdi_destination.length - 1 }}
                      {{ $t("general.titles.others") }})</span
                            >
                          </template>
                          <template v-slot:item="{ item, attrs }">
                            <div style="max-width: 360px">
                              <v-checkbox
                                  v-if="
                          recordSelected.process
                            ? recordSelected.process.processType ===
                              'AGRICULTURAL'
                            : false
                        "
                                  :hide-details="true"
                                  :input-value="attrs.inputValue"
                                  class="pt-2 pb-2 mt-0"
                                  color="primary"
                              >
                                <template v-slot:label>
                                  <read-more
                                      :classes="'mb-0'"
                                      :font-size="14"
                                      :max-chars="80"
                                      :text="item.control_label"
                                  />
                                </template>
                              </v-checkbox>
                              <read-more
                                  v-else
                                  :classes="'mb-0'"
                                  :font-size="14"
                                  :max-chars="80"
                                  :text="item.control_label"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-form>
        <section v-if="existStructures || existGroups">
          <v-layout align-content-center row align-center justify-space-between class="mx-0"
          >
            <v-row v-if="existStructures" class="py-0 px-3">
              <v-col class="mt-8 mb-0 px-0" cols="12">
                <v-card
                  outlined
                  class="mb-3"
                >
                  <v-app-bar dense elevation="0" flat class="neuter--text" color="gray">
                    <h3 class="font-weight-bold">{{ $t("records.titles.general") }}</h3>
                  </v-app-bar>
                  <p class="ma-0 red white--text lighten-2 px-4" v-if="structuresRequired.length > 1 && requiredStructurePages.length > 0">{{ $t("general.titles.requiredPage") }} {{requiredStructurePages.length}}</p>
                  <v-card-text>
                    <LoadingGroupComponent :is-loading="loadingSimpleStructures" />
                    <v-form ref="formGroup" v-model="valid2">
                      <v-row>
                        <v-col
                          cols="4"
                          v-for="(value, index) in paginatedItems"
                        >
                          <SingleStructureComponent
                            :is-group="false"
                            :nomenclators="nomenclators"
                            :key="index"
                            @update:structure="$set(recordSelected.recordStructureValuesOrganized.structures[index], 'structure', $event)"
                            :config="config"
                            :value="value"
                            :valid="valid2"
                            :ref-key="index"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                    <FooterPaginationComponent
                      :valid="valid2"
                      :amount="15"
                      :page="currentStructurePage"
                      :per-page="structuresPerPage"
                      :values="getStructures"
                      :items-per-page="[15, 25, 35]"
                      @update:items="updateItems"
                    />
                  </v-card-text>
                </v-card>
                <!--<v-form ref="formGroup" v-model="valid2">
                  <StructureValuesComponent :nomenclators="nomenclators" :valid="valid2" :values="getStructures" :config="config" />
                </v-form>-->
              </v-col>
            </v-row>
          </v-layout>
          <v-layout align-content-center row align-center justify-space-between>
            <v-row v-if="existGroups" class="my-8 px-3">
              <v-col class="py-0" cols="12">
                <v-card
                  outlined
                  v-for="(group, index) in getGroups"
                  :key="index"
                  class="mb-3"
                >
                  <v-card-title class="pa-0 gray">
                    <v-list-item class="pr-0">
                      <v-list-item-title class="neuter--text">
                        <h3  class="font-weight-bold">
                          {{ translate(group.languageKey, true) }}
                        </h3>
                      </v-list-item-title>
                      <v-badge
                        v-if="!(editedIndex !== -1 && group.groupCode === 'REEN')"
                        :content="group.rows.length"
                        color="neuter"
                        overlap
                        left
                        bordered
                      >
                      <v-card-actions class="pa-0">
                        <v-btn height="5.2vh" tile elevation="0" color="neuter" dense :disabled="!valid[group.id]">
                          <v-icon
                            color="white"
                            large
                            style='font-size: 24px'
                            @click.prevent="addGroupRow(group, index)"
                          >mdi-view-grid-plus-outline
                          </v-icon>
                        </v-btn>
                        </v-card-actions>
                      </v-badge>
                    </v-list-item>
                  </v-card-title>
                  <v-divider></v-divider>
                  <GroupStructuresComponent
                    :is-group="true"
                    :valid="valid[group.id]"
                    @validation:update="
                      updateValidation($event.valid, $event.group)
                    "
                    @contain:required="valid[group.id] = !$event"
                    :config="config"
                    :rows="group.rows"
                    :nomenclators="nomenclators"
                    :group="group"
                    @show:dialog="showDialogForDeleteRow(...$event)"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-layout>
        </section>
        <section v-if="existStructures || existGroups" class="mt-8 mb-12">
          <v-card outlined>
            <v-app-bar dense elevation="0" flat class="mb-4 neuter--text" color="gray">
              <h3 class="font-weight-bold">{{ $t("records.titles.evidence") }}</h3>
            </v-app-bar>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-form ref="formEvidences" class="mx-6">
                    <v-file-input
                        v-model="filesSelected"
                        :label="$t('general.titles.evidence')"
                        :rules="fileTypeRulesAndSize(filesSelected)"
                        multiple
                        small-chips
                        persistent-hint
                        hide-details
                        small
                        flat
                        outlined
                        dense
                        color="primary"
                        prepend-icon=""
                        append-icon="mdi-file-upload-outline"
                        @change="addDocument"
                    >
                    </v-file-input>
                  </v-form>
                </v-col>
                <v-col cols="12">
                  <v-card class="mb-5" elevation="0">
                    <v-list>
                      <v-list-item
                          v-for="(doc, index) in documents"
                          v-bind:key="index"
                      >
                        <v-list-item-avatar>
                          <v-icon>mdi-file-image-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="doc.name"></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-icon color="primary">
                          <v-icon color="error" @click="deleteFile(index)">
                            mdi-minus-circle-outline
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </section>
      </v-container>
      <!--Dialog Group Row confirm-->
      <v-row justify="center">
        <v-dialog v-model="deleteRowDialog" persistent width="600px">
          <v-card>
            <v-card-title class="headline secondary t-bw-secondary--text">{{
              $t("records.titles.titleGroupDelete")
            }}</v-card-title>
            <v-card-text class="pa-6">{{
              $t("records.notification.deleteGroup")
            }}</v-card-text>
            <v-card-actions class="pa-6">
              <v-spacer></v-spacer>
              <v-btn color="neuter" small text @click="cancelDeleteGroup">
                {{ $t("general.buttons.cancel") }}
              </v-btn>
              <v-btn color="error" small elevation="0" class="t-bw-error--text" @click="deleteGroupRow">
                {{ $t("general.buttons.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-footer absolute class="pa-6">
      <v-spacer></v-spacer>
      <v-btn
          :disabled="loading"
          class="mr-1"
          color="neuter"
          elevation="0"
          text
          link
          small
          @click="cancel"
      >
        {{ $t("general.buttons.cancel") }}
      </v-btn>
      <v-btn
          :disabled="!valid1 || validGroup > 0 || validateOnEnter || !valid2 || hasRequiredStructureItems"
          :loading="loading"
          class="mr-4 t-bw-primary--text"
          elevation="0"
          color="primary"
          small
          @click="prepareToSave"
      >
        {{ $t("general.buttons.save") }}
      </v-btn>
    </v-footer>
  </v-layout>
</template>

<script>
  import { mapActions, mapGetters } from "vuex"
  import PermisionService from "../../services/PermisionService"
  import i18n from "@/plugins/i18n"
  import moment from "moment"
  import _ from "lodash"
  import DocumentsService from "../../services/DocumentsService"
  import LanguajeService from "../../services/LanguajeService"
  import sortList from "../../services/OrderListService"
  import Input from "../configuration/input/Input"
  import ReadMore from "../../components/ReadMoreComponent"
  import CompactImages from "../../services/CompactImages"
  import ListComponent from "./components/ListComponent"
  import NomenclatorComponent from "./components/NomenclatorComponent"
  import StructureValuesComponent from "@/module/records/components/commons/StructureValuesComponent"
  import GroupStructuresComponent from "@/module/records/components/commons/GroupStructuresComponent"
  import axios from "axios"
  import SingleStructureComponent from "@/module/records/components/commons/SingleStructureComponent"
  import FooterPaginationComponent from "@/module/records/components/commons/FooterPaginationComponent"
  import LoadingGroupComponent from "@/module/records/components/commons/LoadingGroupComponent";

  export default {
    name: "modal-records",
    components: {
      LoadingGroupComponent,
      FooterPaginationComponent,
      SingleStructureComponent,
      GroupStructuresComponent,
      StructureValuesComponent,
      NomenclatorComponent,
      ListComponent,
      ReadMore,
      Input,
    },
    data() {
      return {
        structureCodes: [
          "P",
          "C",
          "MU",
          "L",
          "CI",
          "LI",
          "MUI",
          "PI",
          "CO",
          "LO",
          "MUO",
          "PO",
        ],
        nomenclators: [
          "PRODUCTSIMPLE",
          "EQUIPMENTSIMPLE",
          "OPERATORSIMPLE",
          "INPUTSIMPLE",
          "SEASONSIMPLE",
          "MEASUREMENTUNITSIMPLE",
          "PRODUCT",
          "EQUIPMENT",
          "OPERATOR",
          "INPUT",
          "SEASON",
          "MEASUREMENTUNIT",
        ],
        deleteRowDialog: false,
        rowData: null,
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "primary",
          itemColor: "primary",
        },
        prerecord: false,
        prerecordId: null,
        filesSelected: [],
        storageData: [],
        storage_device_origin: [],
        storage_device_destination: [],
        // User profile
        profile: null,
        menuRecord: false,
        // Record Data Model
        valid1: false,
        valid2: true,
        valid: {},
        editedIndex: -1,
        // Locations Events
        change_origin: false,
        change_destination: false,
        // Evidences of record
        documents: [],
        documentSelected: null,
        flagGroups: false,
        date: new Date().toISOString().substr(0, 10),
        errorSDO: false,
        errorSDD: false,
        config: null,
        validateOnEnter: false,
        currentStructurePage: 1,
        structuresPerPage: 15,
        source: null,
        processByStage: [],
        loadingSimpleStructures: false,
        loadings: {
          origin: {
            locations: false,
            storages: false
          },
          destination: {
            locations: false,
            storages: false
          }
        },
        origin: {
          locations: null,
          storages: null
        },
        destination: {
          locations: null,
          storages: null
        }
      }
    },
    async created() {
      this.profile = JSON.parse(localStorage.getItem("profile"))
      this.config = JSON.parse(localStorage.getItem("config"))
      // Quitar cuando se vea el tema de la creación por etapas: preguntar a Alberto
      const keys = ["company_id", "pageableDTO"]
      this.filters.company_id = this.profile.company_id
      const filters = _.pick(this.filters, keys)

      const filterLocations = {
        stringFilter: "",
        isActive: true
      }

      const promises = []

      promises.push(this.fetchListPreRecordsByCompanyFiltered([filters, this.$toast]))
      promises.push(this.fetchPrimaryLocations([filterLocations, this.$toast]))

      await Promise.all(promises);

      let index = JSON.parse(localStorage.getItem("records_editOrSave"))
      if (index > -1) {
        this.editedIndex = index
      } else {
        this.recordSelected.record_date_time = new Date()
      }
      if (this.editedIndex !== -1) {
        this.storageData = this.allStoragesByUser
        await this.editItem(this.recordSelected)
      }
    },
    mounted() {
      if (this.$refs && this.$refs.formGroup) this.$refs.formGroup.validate()
    },

    watch: {
      'recordSelected.recordStructureValuesOrganized.groups': {
        handler() {
          const isIgPresent = this.$store.getters['integration/IS_PRESENT']('IG');

          if (isIgPresent) {
            if (this.recordSelected.process && this.recordSelected.process.id === 2345) {
              if (this.recordSelected.recordStructureValuesOrganized && this.recordSelected.recordStructureValuesOrganized.groups) {
                let gi = _.filter(this.recordSelected.recordStructureValuesOrganized.groups, {groupCode: "GI"})
                let go = _.filter(this.recordSelected.recordStructureValuesOrganized.groups, {groupCode: "GO"})

                _.forEach(go, (g, gk) => {
                  _.forEach(g.rows, (r, rk) => {
                    _.forEach(r.values, (v, vk) => {
                      if (["CO"].includes(v.structure.structureCode)) {
                        const percent = gi[gk].rows[rk].values[vk].recordStructureValue.record_value[0] * 0.2
                        // console.log(gi[gk].rows[rk].values[vk].recordStructureValue.record_value[0] - percent)
                        if (v.recordStructureValue.record_value[0] > (gi[gk].rows[rk].values[vk].recordStructureValue.record_value[0] - percent)) {
                          go[gk].rows[rk].values[vk].structure.isValidFlow = false
                        } else {
                          go[gk].rows[rk].values[vk].structure.isValidFlow = true
                        }
                      }
                    })
                  })
                })
              }
            }
          }
        },
        deep: true,
      }
    },

    computed: {
      paginatedItems() {
        const startIndex =
          (this.currentStructurePage - 1) * this.structuresPerPage
        const endIndex = startIndex + this.structuresPerPage

        return this.getStructures.slice(startIndex, endIndex)
      },
      structuresRequired() {
        let requiredItems = [];

        if (this.recordSelected.recordStructureValuesOrganized && this.recordSelected.recordStructureValuesOrganized.structures) {
          this.recordSelected.recordStructureValuesOrganized.structures.forEach((item, index) => {
            if (item.structure&& item.structure.is_required) {
              let page = Math.ceil((index + 1) / this.structuresPerPage);
              requiredItems.push({ index, page });
            }
          });
        }
        return requiredItems;
      },
      requiredStructureItemsWithPages() {
        let requiredItems = [];

        if (this.recordSelected.recordStructureValuesOrganized && this.recordSelected.recordStructureValuesOrganized.structures) {
          this.recordSelected.recordStructureValuesOrganized.structures.forEach((item, index) => {
            if ((item.structure
              && item.structure.is_required)
              && !(item.recordStructureValue.record_value.length > 0
                && (item.recordStructureValue.record_value[0] !== null
                  && item.recordStructureValue.record_value[0] !== ""
                  && item.recordStructureValue.record_value[0] !== undefined)
              )
            ) {
              let page = Math.ceil((index + 1) / this.structuresPerPage);
              requiredItems.push({ index, page });
            }
          });
        }
        return requiredItems;
      },
      hasRequiredStructureItems() {
        return this.requiredStructureItemsWithPages.length > 0;
      },

      requiredStructurePages() {
        return [...new Set(this.requiredStructureItemsWithPages.map(item => item.page))];
      },
      filters: {
        get() {
          return this.$store.getters["ui/GET_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_FILTERS", val)
        },
      },
      recordSelected: {
        get() {
          return this.$store.getters["records/record"]
        },
        set(val) {
          this.$store.commit("records/SET_RECORD", val)
        },
      },
      preRecordSelected: {
        get() {
          return this.$store.getters["prerecords/preRecord"]
        },
        set(val) {
          this.$store.commit("prerecords/SET_PRE_RECORD", val)
        },
      },
      storageDeviceTypesActiveByCompany() {
        return sortList.orderListByUppercase(
          this.$store.getters["storage_type/storageDeviceTypesActiveByCompany"],
          "storage_type_name"
        )
      },
      ...mapGetters({
        authoritiesProcess: "authority/authoritiesProcess",
        allProcess: "proccess/proccess",
        locationsList: "locations/locationsList",
        storagesList: "storage/storagesList",
        allStoragesByUser: "authority/authoritiesStoragesDevices",
        groupDetailsByCompany: "groupDetails/groupDetailsByCompany",
        groupDetailsDefaults: "groupDetails/groupDetailsDefaults",
        requireAndMaxRules: "general/requireAndMaxRules",
        requireRules: "general/requireRules",
        selectRule: "general/selectRule",
        requireAndLotRules: "general/requireAndLotRules",
        lotRules: "general/lotRules",
        fileTypeRulesAndSize: "general/fileTypeRulesAndSize",
        loading: "records/loading",
        preRecordsByCompanyFiltered: "prerecords/prerecordsByCompanyFiltered",
        loadingPreRecords: "prerecords/loading",
        record_edit: "general/record_edit",
        preRecordsByCompany: "prerecords/prerecordsByCompanyFiltered",
      }),
      validGroup() {
        let cant = []

        _.forEach(this.$refs, (ref, key) => {
          if (
            key &&
            (key.includes("datatime") ||
              key.includes("data") ||
              key.includes("time"))
          ) {
            if (ref instanceof Array) {
              _.forEach(ref, r => {
                if (r.valid === false) cant.push(false)
              })
            }
          }
        })

        const valid = _.filter(this.valid, o => {
          return o === false
        })

        return cant.length + valid.length
      },
      decimalFormat() {
        return value =>
          !["CI", "CO", "C"].includes(value.structure.structureCode) &&
          (value.structure.afterPoint !== null ||
            value.structure.afterPoint !== "-")
            ? value.structure.afterPoint
            : this.config.company.traceability.structure.number.afterPoint !==
              null
            ? parseInt(
                this.config.company.traceability.structure.number.afterPoint
              )
            : 3
      },
      // Form condition title
      formTitle() {
        return this.editedIndex === -1
          ? i18n.t("records.gestion.add_title")
          : i18n.t("records.gestion.edit_title")
      },
      // Computed getter origin Secondary Location (Storage) by stage and company
      computedRecordDate: {
        get() {
          return this.recordSelected.record_date_time
            ? new Date(this.recordSelected.record_date_time)
            : null
        },
        set(data) {
          this.recordSelected.record_date_time = data ? new Date(data) : null
        },
      },
      // Exist structures
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized &&
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length >
            0
        )
      },
      // Exist groups
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized &&
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        )
      },
      // Get structures
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures
          ? _.map(
              this.recordSelected.recordStructureValuesOrganized.structures,
              (s, index) => ({ ...s, id: index++ })
            )
          : []
      },
      // Get groups
      getGroups() {
        if (this.flagGroups) this.flagGroups = false
        return this.recordSelected.recordStructureValuesOrganized.groups
          ? _.map(
              this.recordSelected.recordStructureValuesOrganized.groups,
              (g, index) => ({ ...g, id: index++ })
            )
          : []
      },
    },
    methods: {
      ...mapActions({
        saveRecord: "records/saveRecord",
        fetchListPreRecordsByCompanyFiltered:
          "prerecords/fetchListPreRecordsByCompanyFiltered",
        findPreRecordById: "prerecords/findPreRecordById",
        searchProcessStructure: "proccess/searchProcessStructure",
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages"
      }),
      async fetchLocations(e, target) {
        this.$set(this[target], 'storages', [])

        this.$set(this.loadings[target], 'locations', true);

        await this.fetchPrimaryLocations([{
          stringFilter: e || "",
          isActive: true }, this.$toast
        ]).then(() => {
          this.$set(this[target], 'locations', this.locationsList)
        })
        .finally(() => {
          this.$set(this.loadings[target], 'locations', false);
        })
      },
      async fetchStorages(e, pl, target, property, isProductive = true) {
        if (pl) {
          this.$set(this[target], 'storages', [])
          this.$set(this.recordSelected, property, null)

          this.$set(this.loadings[target], 'storages', true);

          await this.fetchStorageLocations([{
            primaryLocationIds: [pl.locationId],
            stringFilter: e || "",
            isActive: true,
            productive: isProductive
          }, this.$toast]).then(() => {
            this.$set(this[target], 'storages', this.storagesList)
          }).finally(() => {
            this.$set(this.loadings[target], 'storages', false);
          })
        }
      },
      updateValidation(valid, group) {
        this.$set(this.valid, group, valid)
      },
      async updateItems(newOptions) {
        this.currentStructurePage = newOptions.page
        this.structuresPerPage = newOptions.itemsPerPage

        const promises = []

        _.forEach(this.paginatedItems, (object, index) => {
          if (this.nomenclators.includes(object.structure.structure_type))
            promises.push(
                this.searchProcessStructure([
                  object.recordStructureValue.record_value,
                  object.recordStructureValue.processStructureId.id,
                  this.source,
                ])
                    .then(res => {
                      this.$set(this.recordSelected.recordStructureValuesOrganized.structures[index], "structure", {
                        dataToSelect: res.dataToSelect,
                        ...object.structure,
                        ...res,
                      })
                    })
                    .catch(console.log)
            )
        })

        await Promise.all(promises).finally(() => {
          this.loadingSimpleStructures = false
        })
      },
      // Computed getter processes by stage (Storage Device Type)
      processBySelectedStage(storageSelected) {
        this.processByStage = storageSelected
          ? _.filter(this.authoritiesProcess, process => {
              return (
                process.sdt_to_show.id ===
                storageSelected.storage_device_type_id
              )
            })
          : []
      },
      async searchNomenclator(e, object) {
        if (this.source) this.source.cancel()

        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        await this.searchProcessStructure([
          [e],
          object.recordStructureValue.processStructureId.id,
          this.source,
        ])
          .then(res => {
            this.$set(object, "structure", {
              dataToSelect: res.dataToSelect,
              ...object.structure,
              ...res,
            })
          })
          .catch(console.log)
      },
      clearSDO(event) {
        if (event instanceof Array) {
          this.errorSDO = event.length === 0
        }
      },
      clearSDD(event) {
        if (event instanceof Array) {
          this.errorSDD = event.length === 0
        }
      },
      // Get Record Structure Values Save Model
      getRecordStructureValueModel() {
        return _.cloneDeep({
          groups: {
            key: "groups",
            values: [],
          },
          structures: {
            key: "structures",
            values: [],
          },
        })
      },
      // Bug refresh select, (retirar al encontrar solución)
      bugRefresh(isRequired) {
        console.log("refresh")

        let count = 0

        if (isRequired) {
          count = _.filter(this.valid, o => {
            return o === false
          })

          this.validateOnEnter = count > 0 || !this.valid2
        }
      },
      // Translate function (consume Language Service)
      translate(key) {
        return LanguajeService.getKey3(key)
      },
      // Translate data process for edit records
      translateProcessData() {
        this.recordSelected.process.structures = _.map(
          this.recordSelected.process.structures,
          structure => {
            structure.dataToSelect =
              structure.dataToSelect.length > 0
                ? _.map(structure.dataToSelect, details => {
                    return {
                      ...details,
                      name: LanguajeService.getKey3(details.name),
                    }
                  })
                : structure.dataToSelect
            return structure
          }
        )
      },
      // Prepare record to edit
      async editItem(data) {
        let item = Object.assign({}, data)
        this.recordSelected.storage_device_type_id =
          this.storageDeviceTypesActiveByCompany.find(
            x => x.storage_device_type_id === item.process.sdt_to_show.id
          )

        this.processBySelectedStage(this.recordSelected.storage_device_type_id)

        this.recordSelected.process = this.allProcess.find(
          x => x.id === item.process.id
        )

        console.log(this.recordSelected.process)

        _.forEach(
          item.recordStructureValuesOrganized.structures,
          async object => {
            if (
              ["DATA", "TIME", "DATATIME"].includes(
                object.structure.structure_type
              )
            ) {
              if (
                object.recordStructureValue.record_value[0] &&
                object.recordStructureValue.record_value[0].length > 0 &&
                object.recordStructureValue.record_value[0] !== null
              ) {
                let date = object.recordStructureValue.record_value[0].slice(
                  0,
                  19
                )
                object.recordStructureValue.record_value[0] = new Date(date)
              } else {
                object.recordStructureValue.record_value = []
              }
            } else if (
              object.structure.structure_type === "NUMBER"
            ) {
              if (object.recordStructureValue.record_value.length > 0) {
                if (object.recordStructureValue.record_value[0] === null ||
                  object.recordStructureValue.record_value[0] === "") {
                  object.recordStructureValue.record_value[0] = null
                }
              } else {
                object.recordStructureValue.record_value = [null]
              }
            }
          }
        )

        // groups
        _.forEach(
          item.recordStructureValuesOrganized.groups,
          (group, index) => {
            _.forEach(group.rows, row => {
              _.forEach(row.values, async item => {
                if (
                  item.structure.structure_type === "NUMBER"

                ) {
                  if (item.recordStructureValue.record_value.length > 0) {
                    if (item.recordStructureValue.record_value[0] === null ||
                      item.recordStructureValue.record_value[0] === "") {
                      item.recordStructureValue.record_value[0] = null
                    }
                  } else {
                    item.recordStructureValue.record_value = [null]
                  }
                }
              })
            })

            _.forEach(group.rows, row => {
              _.forEach(row.values, async value => {
                if (
                  ["DATA", "TIME", "DATATIME"].includes(
                    value.structure.structure_type
                  )
                ) {
                  if (
                    value.recordStructureValue.record_value[0] &&
                    value.recordStructureValue.record_value[0].length > 0
                  ) {
                    let date = value.recordStructureValue.record_value[0].slice(
                      0,
                      19
                    )
                    value.recordStructureValue.record_value[0] = new Date(date)
                  } else {
                    value.recordStructureValue.record_value = []
                  }
                }
              })
            })
          }
        )

        if (this.recordSelected.sdi_origin) {
          this.recordSelected.location_origin = _.cloneDeep(
            item.sdi_origin.primaryLocation
          )
          // this.changeLocationOriginEvent()
          // this.recordSelected.sdi_origin = item.sdi_origin

          await this.fetchPrimaryLocations([{
            versionIds: [this.recordSelected.sdi_origin.primaryLocation.id],
            onlyCurrentVersion: false
          },
          this.$toast]).then(() => {
            this.$set(this.origin, 'locations', this.locationsList)
          })

          await this.fetchStorageLocations([{
            primaryLocationVersionIds: [this.recordSelected.sdi_origin.primaryLocation.id],
            versionIds: [this.recordSelected.sdi_origin.id],
            onlyCurrentVersion: false
          },
          this.$toast]).then(() => {
            this.$set(this.origin, 'storages', this.storagesList)
          })
        }
        if (this.recordSelected.sdi_destination) {
          this.recordSelected.location_destination = _.cloneDeep(
            item.sdi_destination.primaryLocation
          )

          await this.fetchPrimaryLocations([{
            versionIds: [this.recordSelected.sdi_destination.primaryLocation.id],
            onlyCurrentVersion: false
          },
            this.$toast]).then(() => {
            this.$set(this.destination, 'locations', this.locationsList)
          })

          await this.fetchStorageLocations([{
            primaryLocationVersionIds: [this.recordSelected.sdi_destination.primaryLocation.id],
            versionIds: [this.recordSelected.sdi_destination.id],
            onlyCurrentVersion: false
          },
            this.$toast]).then(() => {
            this.$set(this.destination, 'storages', this.storagesList)
          })
          // this.changeLocationDestinEvent()
          // this.recordSelected.sdi_destination = item.sdi_destination
        }

        if (
          (this.recordSelected.process && this.recordSelected.process.processType === "AGRICULTURAL") &&
          this.editedIndex === -1
        ) {
          this.recordSelected.sdi_origin = [this.recordSelected.sdi_origin]
          this.recordSelected.sdi_destination = [
            this.recordSelected.sdi_destination,
          ]
        }
        this.recordSelected.record_date_time = item.record_date_time
          ? item.record_date_time
          : new Date()
        this.recordSelected.evidences = this.recordSelected.evidences
          ? this.recordSelected.evidences
          : {}
        this.translateProcessData()
        if (
          this.recordSelected.evidences &&
          this.recordSelected.evidences.length > 0
        )
          this.getEvidences(this.recordSelected.id)

        const promises = []

        this.loadingSimpleStructures = true

        _.forEach(this.paginatedItems, (object, index) => {
          if (this.nomenclators.includes(object.structure.structure_type))
            promises.push(
                this.searchProcessStructure([
                  object.recordStructureValue.record_value,
                  object.recordStructureValue.processStructureId.id,
                  this.source,
                ])
                    .then(res => {
                      this.$set(this.recordSelected.recordStructureValuesOrganized.structures[index], "structure", {
                        dataToSelect: res.dataToSelect,
                        ...object.structure,
                        ...res,
                      })
                    })
                    .catch(console.log)
            )
        })

        await Promise.all(promises).finally(() => {
          this.loadingSimpleStructures = false
        })

        this.resetValidationGroups()
      },

      resetValidationGroups() {
        if (this.$refs && this.$refs.length > 0) {
          if (this.$refs.formStructures)
            this.$refs.formStructures.resetValidation()
        }
      },

      // Function Event Change Origin Location
      changeLocationOriginEvent() {
        if (
          this.recordSelected.sdi_origin &&
          this.recordSelected.sdi_origin.length > 0
        ) {
          this.recordSelected.sdi_origin = null
        } else {
          this.recordSelected.sdi_origin = null
        }
        this.errorSDO = false
        this.storage_device_origin = this.recordSelected.location_origin
          ? sortList.orderListByUppercase(
              _.filter(this.storageData, storage => {
                return (
                  storage.primaryLocation.id ===
                    this.recordSelected.location_origin.id &&
                  storage.productive === true
                )
              }),
              "control_label"
            )
          : []
      },
      // Function Event Change Destination Location
      changeLocationDestinEvent() {
        if (
          this.recordSelected.sdi_destination &&
          this.recordSelected.sdi_destination.length > 0
        ) {
          this.recordSelected.sdi_destination = null
        } else {
          this.recordSelected.sdi_destination = null
        }
        this.errorSDD = false
        this.storage_device_destination = this.recordSelected
          .location_destination
          ? sortList.orderListByUppercase(
              _.filter(this.storageData, storage => {
                return (
                  storage.primaryLocation.id ===
                    this.recordSelected.location_destination.id &&
                  storage.productive === true
                )
              }),
              "control_label"
            )
          : []
      },
      // Get Evidences to Edit
      getEvidences(recordId) {
        const scope = this
        try {
          DocumentsService.getEvidencas(recordId).then(response => {
            const blob = new Blob([response.data], {
              type: "application/zip",
            })
            const jsZip = require("jszip")
            jsZip.loadAsync(blob).then(function (zip) {
              Object.keys(zip.files).forEach(function (filename) {
                zip.files[filename].async("blob").then(function (fileData) {
                  // These are your file contents
                  let type = ""
                  const nameSpliter = filename.split(".")
                  if (nameSpliter[1] === "pdf" || nameSpliter[1] === "PDF") {
                    type = "application/pdf"
                  } else {
                    type = "image/" + nameSpliter[1]
                  }
                  scope.addDocument(
                    [
                      new File([fileData], filename, {
                        type: type,
                      }),
                    ],
                    true
                  )
                })
              })
            })
          })
        } catch (error) {
          this.$toast.info(i18n.t("records.notification.evidencesCero"), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        }
      },
      // Check permission
      check(permissions) {
        return PermisionService.check(permissions)
      },
      // Add group action on details (structures) step
      addGroupRow(group, index) {
        const rowCopy = {
          values: _.map(_.cloneDeep(group.rows[0].values), value => {
            if (value.structure.structure_type === "NUMBER") {
              value.recordStructureValue.record_value[0] = null
            } else {
              value.recordStructureValue.record_value = []
            }
            return value
          }),
          groupRow: 1,
        }

        this.$set(group, 'rows', _.forEach(group.rows, row => {
          row.groupRow = row.groupRow + 1
        }))

        group.rows.splice(0, 0, rowCopy)

        this.$root.$emit("update:page-group", {
          page: 1,
          itemsPerPage: 10,
          isAdd: true
        })

        this.flagGroups = true
      },
      showDialogForDeleteRow(rows, index) {
        this.deleteRowDialog = true
        this.rowData = {
          rows: rows,
          index: index,
        }
      },
      // Delete group action on details (structures) step
      deleteGroupRow() {
        if (this.rowData) {
          let { rows, index } = this.rowData;
          rows.forEach((row, i) => {
            if (i > index) {
              row.groupRow -= 1;
            }
          });
          rows.splice(index, 1);
          this.flagGroups = true;
          this.deleteRowDialog = false;
          this.rowData = null;
        }
      },
      cancelDeleteGroup() {
        this.flagGroups = false
        this.deleteRowDialog = false
        this.rowData = null
      },
      // Change Pre-record event
      async changePrerecordField(event) {
        this.$store.commit("records/SET_ORIGINAL_STATE_RECORD")
        this.$refs.form.resetValidation()

        if (this.prerecordId) {
          await this.findPreRecordById([this.prerecordId, this.$toast]).finally(() => {
            this.recordSelected = this.preRecordSelected
            this.recordSelected.id = null
            this.editItem(this.recordSelected)
            this.editedIndex = -1
          })
        }
        this.errorSDO = false
        this.errorSDD = false
      },
      // Event action on select Stages (Storage Device Type)
      async selectSDTisFarm() {
        if (
          this.recordSelected.storage_device_type_id &&
          this.recordSelected.storage_device_type_id.isFarm
        ) {
          this.destination.locations = this.origin.locations
          this.recordSelected.location_destination = this.recordSelected.location_origin

          this.destination.storages = this.origin.storages
          this.recordSelected.sdi_destination = this.recordSelected.sdi_origin
        }
      },
      // Event action on change some field on form
      changeField(stage, event) {
        if (event === "sdt") {
          this.processBySelectedStage(stage)
          this.recordSelected.process = null
          this.recordSelected.recordStructureValuesOrganized =
            this.getRecordStructureValueModel()
        } else if (event === "process") {
          if (stage) {
            if (this.recordSelected.process === undefined) {
              this.recordSelected.recordStructureValuesOrganized = null
            } else {
              this.recordSelected.recordStructureValuesOrganized = _.cloneDeep(
                  this.recordSelected.process.processStructureOrganized
              )
              if (
                  this.recordSelected.recordStructureValuesOrganized.structures &&
                  this.recordSelected.recordStructureValuesOrganized.structures
                      .length > 0
              ) {
                _.forEach(
                    this.recordSelected.recordStructureValuesOrganized.structures,
                    item => {
                      if (item.structure.structure_type === "NUMBER") {
                        item.recordStructureValue.record_value[0] = null
                      }
                    }
                )
              }
              if (
                  this.recordSelected.recordStructureValuesOrganized.groups &&
                  this.recordSelected.recordStructureValuesOrganized.groups.length >
                  0
              ) {
                _.forEach(
                    this.recordSelected.recordStructureValuesOrganized.groups,
                    (group, index) => {
                      group["index"] = index + 1
                      _.forEach(group.rows, (row, index) => {
                        _.forEach(row.values, item => {
                          if (item.structure.structure_type === "NUMBER") {
                            item.recordStructureValue.record_value[0] = null
                          }
                        })
                      })
                    }
                )
              }
            }
          } else {
            this.recordSelected.recordStructureValuesOrganized = []
          }
        } else {
          this.recordSelected.recordStructureValuesOrganized = []
        }
        this.recordSelected.location_origin = null
        this.recordSelected.sdi_origin = null
        this.recordSelected.location_destination = null
        this.recordSelected.sdi_destination = null
        this.errorSDO = false
        this.errorSDD = false
        this.$refs.form.resetValidation()
      },
      // Attach evidence to registry of record
      addDocument(docs, edit) {
        const mimeType = [
          "image/jpeg",
          "image/jpg",
          "image/bmp",
          "image/png",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "text/csv",
          "text/xml",
          "application/pdf",
          "application/zip",
          "application/x-zip-compressed",
          "application/vnd.rar",
        ]
        const scope = this

        _.forEach(docs, function (doc) {
          const split = doc.name.split(".")
          const extension = doc ? split[split.length - 1] : null

          if (
            [
              "jpg",
              "jpeg",
              "png",
              "pdf",
              "rar",
              "zip",
              "xml",
              "xlsx",
              "csv",
            ].includes(extension)
          ) {
            if (["jpg", "jpeg", "png", "bmp"].includes(extension)) {
              CompactImages.downscaleImage(0.7, doc, function (data) {
                if (
                  edit ||
                  (data.size <= 5000000 && mimeType.includes(data.type))
                )
                  scope.documents.push(data)
              })
            } else {
              scope.documents.push(doc)
            }
          }
        })
        this.document = null
      },
      // Delete selected evidence to registry of record
      deleteFile(index) {
        this.documents.splice(index, 1)
      },
      // Cancel registry record operation
      cancel() {
        this.$store.commit("records/SET_ORIGINAL_STATE_RECORD")
        this.$router.push("/records")
      },
      // Prepare data to save Records
      prepareToSave() {
        const item = _.cloneDeep(this.recordSelected)
        let items = { recordList: [] }
        let totalArea = 0
        const pre_record_id = this.prerecord ? this.prerecordId : null
        item.id = this.prerecord ? null : item.id
        item.originApp = false
        item.company = { id: this.profile.company_id }
        item.person_in_charge = { id: this.profile.id }
        item.storage_device_type_id = null

        if (item.record_date_time)
          item.record_date_time = moment(this.recordSelected.record_date_time)
            .format()
            .slice(0, 19)

        item.evidences = []
        const document = {
          documentAuthority: { id: 1 },
          documentType: { id: 1 },
          documentDescription: { id: 1 },
          company: { id: item.company.id },
        }
        if (this.recordSelected.process.processType === "AGRICULTURAL") {
          if (item.id) {
            item.sdi_origin = [item.sdi_origin]
          }
          if (item.sdi_origin.length > 0) {
            _.forEach(item.sdi_origin, storage => {
              const area = Number(storage.area);
              if (!isNaN(area) && area > 0) {
                totalArea += area;
              }
            });
          }
          console.log(totalArea)
          console.log(item)
          _.forEach(item.sdi_origin, origin => {
            let structuresCopy = _.cloneDeep(
              item.recordStructureValuesOrganized
            )
            const structuresAgri = []
            const item_copy = _.cloneDeep(item)
            _.forEach(structuresCopy.structures, structure => {
              this.transformStructure(structure)

              let value =
                totalArea && totalArea > 0
                  ? this.calculateArea(structure, origin.area, totalArea)
                  : structure.recordStructureValue

              value.record_value = _.without(value.record_value, null)
              structuresAgri.push(value)
            })
            _.forEach(structuresCopy.groups, group => {
              _.forEach(group.rows, row => {
                _.forEach(row.values, structure => {
                  this.transformStructure(structure)

                  let value =
                    totalArea && totalArea > 0
                      ? this.calculateArea(structure, origin.area, totalArea)
                      : structure.recordStructureValue

                  value.groupRow = row.groupRow
                  structuresAgri.push(value)
                })
              })
            })
            item_copy.recordStructureValues = structuresAgri
            item_copy.sdi_origin = { id: origin.id }
            item_copy.sdi_destination = { id: origin.id }
            item_copy.location_destination = null
            item_copy.location_origin = null
            item_copy.recordStructureValuesOrganized = null
            items.recordList.push(item_copy)
          })
        }
        if (
          this.recordSelected.process.processType === "SHIPPING" ||
          this.recordSelected.process.processType === "RECEIPT"
        ) {
          item.sdi_origin = { id: item.sdi_origin.id }
          item.sdi_destination = { id: item.sdi_destination.id }
          let structuresCopy = _.cloneDeep(item.recordStructureValuesOrganized)
          let cantRecords = 1
          const groupReen = _.find(structuresCopy.groups, {
            groupCode: "REEN",
          })
          if (groupReen) {
            cantRecords = groupReen.rows.length
          }
          let structuresREEN = []
          _.forEach(structuresCopy.structures, structure => {
            this.transformStructure(structure)

            structure.recordStructureValue.record_value = _.without(
              structure.recordStructureValue.record_value,
              null
            )
            structuresREEN.push(structure.recordStructureValue)
          })
          _.forEach(structuresCopy.groups, group => {
            if (group.groupCode !== "REEN") {
              _.forEach(group.rows, row => {
                _.forEach(row.values, structure => {
                  this.transformStructure(structure)

                  structure.recordStructureValue.groupRow = row.groupRow
                  structuresREEN.push(structure.recordStructureValue)
                })
              })
            }
          })
          while (cantRecords > 0) {
            const structuresSave = _.cloneDeep(structuresREEN)
            const itemCopy = _.cloneDeep(item)
            _.forEach(groupReen.rows[cantRecords - 1].values, structure => {
              this.transformStructure(structure)

              structure.recordStructureValue.groupRow = 1
              structuresSave.push(structure.recordStructureValue)
            })
            itemCopy.recordStructureValues = structuresSave
            itemCopy.location_destination = null
            itemCopy.location_origin = null
            itemCopy.recordStructureValuesOrganized = null
            items.recordList.push(itemCopy)
            cantRecords--
          }
        }
        if (this.recordSelected.process.processType === "PROCESSING") {
          let structuresCopy = _.cloneDeep(item.recordStructureValuesOrganized)
          item.sdi_origin = { id: item.sdi_origin.id }
          item.sdi_destination = { id: item.sdi_destination.id }
          const structuresProcess = []
          _.forEach(structuresCopy.structures, structure => {
            this.transformStructure(structure)
            structure.recordStructureValue.record_value = _.without(
              structure.recordStructureValue.record_value,
              null
            )
            structuresProcess.push(structure.recordStructureValue)
          })
          _.forEach(structuresCopy.groups, group => {
            _.forEach(group.rows, row => {
              _.forEach(row.values, structure => {
                this.transformStructure(structure)

                structure.recordStructureValue.groupRow = row.groupRow
                structuresProcess.push(structure.recordStructureValue)
              })
            })
          })
          item.recordStructureValuesOrganized = null
          item.recordStructureValues = structuresProcess
          items.recordList.push(item)
        }

        this.save(
          items,
          document,
          this.documents,
          this.editedIndex,
          pre_record_id
        )
      },
      // Calculate value depending of location area
      calculateArea(structure, originArea, totalArea) {
        if (structure.structure.structure_type === "NUMBER") {
          if (
            structure.recordStructureValue.record_value[0] === "" ||
            structure.recordStructureValue.record_value[0] === null ||
            structure.recordStructureValue.record_value[0] === 0
          ) {
            structure.recordStructureValue.record_value = [0]
          } else {
            const percent = (originArea * 100) / totalArea
            structure.recordStructureValue.record_value[0] =
              Math.floor(
                structure.recordStructureValue.record_value[0] * percent
              ) / 100
          }
        }
        return structure.recordStructureValue
      },
      // Save Records
      save(items, document, documents, editedIndex, pre_record_id) {
        this.saveRecord([
          items,
          document,
          documents,
          editedIndex,
          pre_record_id,
          this.$toast,
        ]).finally(() => {
          this.$router.push({ path: "/records" })
        })
      },
      transformStructure(structure) {
        if (["TIME", "DATATIME", "DATA"].includes(structure.structure.structure_type)) {
          if (structure.recordStructureValue.record_value &&
            structure.recordStructureValue.record_value.length > 0 &&
            structure.recordStructureValue.record_value[0] !== null) {
            let date = moment(structure.recordStructureValue.record_value[0].toString().slice(0, 24)).format();
            structure.recordStructureValue.record_value[0] = date.slice(0, 19);
          } else {
            structure.recordStructureValue.record_value = [];
          }
        } else if (structure.structure.structure_type === "NUMBER") {
          if (structure.recordStructureValue.record_value[0] === "" ||
            structure.recordStructureValue.record_value[0] === null) {
            if (!structure.structure.is_required)
              structure.recordStructureValue.record_value = [];
            else
              structure.recordStructureValue.record_value = [parseFloat(0).toFixed(this.decimalFormat(structure))];
          } else {
            let value = structure.recordStructureValue.record_value[0].toString();
            let [integerPart, decimalPart = ""] = value.split(".");
            const requiredDecimals = this.decimalFormat(structure);
            if (decimalPart.length > requiredDecimals) {
              decimalPart = decimalPart.slice(0, requiredDecimals);
            } else {
              decimalPart = decimalPart.padEnd(requiredDecimals, '0');
            }
            structure.recordStructureValue.record_value[0] = integerPart + (decimalPart ? "." + decimalPart : "");
          }
        }
      },
    },

    destroyed() {
      this.$root.$off('update:structure')
    }
  }
</script>

<style scoped></style>
